var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedPerfume)?_c('div',{staticClass:"product-view"},[(_vm.smelling)?_c('SmellingView',{staticClass:"smelling-component",attrs:{"ean":_vm.selectedPerfume.kodomizerEan,"color":_vm.selectedPerfume.backgroundColor,"duration":7000},on:{"close":_vm.handleClose}}):_vm._e(),_c('div',{staticClass:"product-view_main",attrs:{"role":"main"}},[_c('div',{staticClass:"left",style:({
        backgroundColor: _vm.selectedPerfume.backgroundColor,
        color: _vm.pickTextColorBasedOnBgColorSimple(
          _vm.selectedPerfume.backgroundColor
        ),
      })},[_c('img',{attrs:{"src":_vm.selectedPerfume.imageUrl,"alt":_vm.selectedPerfume.title}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"result-title"},[_vm._v(_vm._s(_vm.$t("result_title")))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.selectedPerfume.title))]),_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.$t('eau_de_parfum'))}}),_c('div',{staticClass:"title-section"},[_vm._v(_vm._s(_vm.$t("description")))]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.selectedPerfume.description)}}),_c('div',{staticClass:"separator",style:({
              backgroundColor: _vm.pickTextColorBasedOnBgColorSimple(
                _vm.selectedPerfume.backgroundColor
              ),
            })}),_c('div',{staticClass:"title-section"},[_vm._v(_vm._s(_vm.$t("flavors")))]),_c('ul',{staticClass:"ingredients-list"},_vm._l((_vm.ingredients),function(ing){return _c('li',{key:ing.id,staticClass:"ingredients-item"},[_vm._v(" "+_vm._s(ing.name)+" ")])}),0),_c('div',{staticClass:"separator",style:({
              backgroundColor: _vm.pickTextColorBasedOnBgColorSimple(
                _vm.selectedPerfume.backgroundColor
              ),
            })}),_c('intensities-table',{attrs:{"end":true,"perfume":_vm.selectedPerfume,"color":_vm.pickTextColorBasedOnBgColorSimple(
                _vm.selectedPerfume.backgroundColor
              )}})],1),(_vm.isAirParfum)?_c('button',{staticClass:"btn btn--border product-view_smell-btn",style:({
            backgroundColor: _vm.pickTextColorBasedOnBgColorSimple(
              _vm.selectedPerfume.backgroundColor
            ),
            color: _vm.selectedPerfume.backgroundColor,
          }),domProps:{"innerHTML":_vm._s(_vm.$t('smell_again'))},on:{"click":_vm.startSmell}}):_vm._e()])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"result-title"},[_vm._v(_vm._s(_vm.$t("result_you_may_like")))]),_c('div',{staticClass:"perfume-wrapper"},_vm._l((_vm.filterAllPerfumes),function(perfume){return _c('perfume-card',{key:perfume.id,attrs:{"perfume":perfume},on:{"click":() => _vm.handlePerfumeSelected(perfume)}})}),1)])]),_c('div',{staticClass:"back",on:{"click":_vm.onBackToHome}},[_vm._v(" "+_vm._s(_vm.$t("back_to_home"))+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }