<template>
  <div v-if="selectedPerfume" class="product-view">
    <SmellingView
      class="smelling-component"
      v-if="smelling"
      :ean="selectedPerfume.kodomizerEan"
      :color="selectedPerfume.backgroundColor"
      :duration="7000"
      @close="handleClose"
    />
    <div role="main" class="product-view_main">
      <div
        class="left"
        :style="{
          backgroundColor: selectedPerfume.backgroundColor,
          color: pickTextColorBasedOnBgColorSimple(
            selectedPerfume.backgroundColor
          ),
        }"
      >
        <img :src="selectedPerfume.imageUrl" :alt="selectedPerfume.title" />
        <div class="container">
          <div class="container-wrapper">
            <div class="result-title">{{ $t("result_title") }}</div>
            <div class="title">{{ selectedPerfume.title }}</div>
            <div class="subtitle" v-html="$t('eau_de_parfum')"></div>
            <div class="title-section">{{ $t("description") }}</div>
            <div class="description" v-html="selectedPerfume.description" />
            <div
              class="separator"
              :style="{
                backgroundColor: pickTextColorBasedOnBgColorSimple(
                  selectedPerfume.backgroundColor
                ),
              }"
            ></div>
            <div class="title-section">{{ $t("flavors") }}</div>
            <ul class="ingredients-list">
              <li
                class="ingredients-item"
                v-for="ing in ingredients"
                :key="ing.id"
              >
                {{ ing.name }}
              </li>
            </ul>
            <div
              class="separator"
              :style="{
                backgroundColor: pickTextColorBasedOnBgColorSimple(
                  selectedPerfume.backgroundColor
                ),
              }"
            ></div>
            <intensities-table
              :end="true"
              :perfume="selectedPerfume"
              :color="
                pickTextColorBasedOnBgColorSimple(
                  selectedPerfume.backgroundColor
                )
              "
            />
          </div>
          <button
            v-if="isAirParfum"
            class="btn btn--border product-view_smell-btn"
            @click="startSmell"
            v-html="$t('smell_again')"
            :style="{
              backgroundColor: pickTextColorBasedOnBgColorSimple(
                selectedPerfume.backgroundColor
              ),
              color: selectedPerfume.backgroundColor,
            }"
          />
        </div>
      </div>
      <div class="right">
        <div class="result-title">{{ $t("result_you_may_like") }}</div>
        <div class="perfume-wrapper">
          <perfume-card
            v-for="perfume in filterAllPerfumes"
            :perfume="perfume"
            :key="perfume.id"
            @click="() => handlePerfumeSelected(perfume)"
          ></perfume-card>
        </div>
      </div>
    </div>
    <div class="back" @click="onBackToHome">
      {{ $t("back_to_home") }}
    </div>
  </div>
</template>
<script>
import queryStringMixin from "../mixins/queryStringMixin";
import SmellingView from "../components/SmellingView";
import { PERFUME, WIZARD_V2 } from "../router";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import IntensitiesTable from "../components/IntensitiesTable.vue";
import PerfumeCard from "../components/PerfumeCard.vue";
import { pickTextColorBasedOnBgColorSimple } from "../utils/colorUtils";
export default {
  components: {
    SmellingView,
    IntensitiesTable,
    PerfumeCard,
  },
  mixins: [queryStringMixin],
  data() {
    return {
      recommended: null,
      smelling: false,
      smellingTimeout: null,
      currentId:null
    }
  },
  fetch: {
    selectedPerfume: {
      model: "allCategoriesPerfumes",
      method: "getById",
      params() {
        return [this.$route.params.perfume, this.$route.params.catalogue];
      },
    },
    allPerfumesFetched: {
      model: 'allCategoriesPerfumes',
      method: 'get',
      params(){ return [this.gender, this.$route.params.catalogue,this.$route.params.category,]}
    
    }
  },
  computed: {
    displayedIngredients() {
      return this.selectedPerfume.ingredientProportions.slice(0, 3);
    },
    filterAllPerfumes() {
      return this.allPerfumesFetched?.filter(perfume => perfume.id !== this.selectedPerfume.id) ?? []
    },
    ingredients() {
      return this.selectedPerfume.ingredientProportions
        .slice(0, 3)
        .map((e) => e.ingredient);
    },
    getQualities() {
      if (this.gender === "F") {
        return [
          {
            key: "sweet",
            attribute: "womanSweet",
          },
          {
            key: "floral",
            attribute: "womanFloral",
          },
          {
            key: "lasting",
            attribute: "womanLasting",
          },
        ];
      } else {
        return [
          {
            key: "freshness",
            attribute: "manFreshness",
          },
          {
            key: "intensity",
            attribute: "manIntensity",
          },
          {
            key: "lasting",
            attribute: "manLasting",
          },
        ];
      }
    },
  },
  watch: {
    selectedPerfume: {
      handler(newValue, oldValue) {
        if (newValue && newValue.id) {
          if (!oldValue || oldValue.id !== newValue.id) {
            this.updatePerfume();
            this.currentId = this.selectedPerfume.id
          }
        }
      },
      immediate:true,
    }
  },
  methods: {
    pickTextColorBasedOnBgColorSimple,
    onBackToHome() {
      this.$router.push({ name: WIZARD_V2 });
    },
    handleClose() {
      this.smelling = false;
      clearTimeout(this.smellingTimeout);
    },
    handlePerfumeSelected(perfume) {
      this.$router.push({
        name: PERFUME,
        params: {
          category: this.$route.params.category,
          perfume: perfume.id,
        },
        query: {
          gender: this.gender,
        },
      });
    },
    updatePerfume() {
      this.eventTracker.emitEvent('VIEW_OPENED', {
        type: 'PERFUME_VIEWED',
        data: {
          perfume: this.$route.params.perfume,
          category: this.$route.params.category
        }
      })
      this.startSmell();
    },
    isAirParfum() {
      return true;
    },
    startSmell() {
      this.smelling = true;
      this.eventTracker.emitEvent("ACTION", {
        type: "SMELL",
        data: { perfume: this.$route.params.perfume },
      });
      this.smellingTimeout = setTimeout(() => {
        this.smelling = false;
      }, 8000);
    },
  },
};
</script>
<style lang="stylus" scoped>
.product-view
  m-font-size(16, 26)
  display: flex
  flex-direction: column
  height: 100vh

  .back
    m-font("Paco")
    m-font-size(14, 17)
    cursor: pointer
    display: flex
    flex-direction: column
    height: $header-height
    justify-content: center
    position: absolute
    right: vw(70px)
    text-transform: uppercase
    z-index: 1

    &:after
      background-color: #000
      content: ""
      display: block
      height: 1px
      margin-top: vw(3px)
      width: 100%

.product-view_main
  display: flex
  height: 100%

  img
    object-fit: contain
    width: 40%

  .result-title
    m-first-letter-case()
    m-font("Paco")
    m-font-size(18, 18)
    margin-bottom: vh(45px)

  .left,
  .right
    height: 100%

  .left
    display: flex
    flex: 0 0 60%
    padding: vw(70px)

    .container
      display: flex
      flex-direction: column
      margin-left: vw(70px)

    .container-wrapper
      display: flex
      flex: 1 1 0%
      flex-direction: column
      overflow: hidden

    .title
      m-font("DIN Engschrift Std")
      m-font-size(33, 33)
      margin-bottom: vh(14px)
      text-transform: uppercase

    .subtitle
      m-first-letter-case()
      m-font-size(15, 15)
      margin-bottom: vh(70px)

    .title-section
      m-font("Paco")
      m-font-size(14, 18)
      margin-bottom: vh(20px)
      text-transform: uppercase

    .description
      m-font-size(15, 25)
      max-height: vh(175px)
      overflow-y: auto
      text-align: justify

    .separator
      background-color: red
      height: 1px
      margin-bottom: vh(20px)
      margin-top: vh(20px)
      opacity: 0.25

    .ingredients-item
      m-font-size(15, 25)

    >>> .intensities
      margin: 0

    .btn
      margin-top: vh(20px)

  .right
    display: flex
    flex: 0 0 40%
    flex-direction: column
    padding: vw(70px)
    padding-bottom: 0

    .perfume-wrapper
      column-gap: vw(2px)
      display: flex
      display: grid
      grid-template-columns: repeat(2, 1fr)
      overflow-y: auto

      > .perfume-card
        display: flex
        flex-direction: column

        &:last-child
          margin-bottom: vw(45px)

        >>> .top-image
          flex: 0 0 vh(250px)
          height: vh(250px)

          &:before
            display: none

          .background
            display: none

        >>> .bottom
          padding: vh(15px) 0
</style>